ul,li,p {
	display: block;
	margin: 0;
	padding: 0;
}

/* p:before { list-style-image: url("../../../assets/icons/logo-small.svg"); }  */

.menu-wrap {
	display: flex;
	align-items: center; /* Center vertically */
  	justify-content: center; /* Center horizontally */
}

.menu {
	transform-origin: 50% 50%;
	transition: transform .2s;
}
.menu:before {
	content: "";
	position: absolute;
	width: 80px;
	height: 80px;
	top: 50%;
	left: 50%;
	margin: -40px 0 0 -40px;
	z-index: 3;
}

.menu .item {
	position: absolute;
	width: 100vw;
	height: 100vh;
	bottom: 20%;
	left: 50%;
	transform-origin: 0% 100%;
	overflow: hidden;
}
.menu .item-inner {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	transform-origin: 0% 100%;
	display: flex;
	text-decoration: none;
	font-size: 3em !important;
	line-height: 0;
	color: #e8cfd5;
}
.menu .item.on .item-inner {
	color: #000000;
    font-size: 3.3em !important;
}
.menu .txt {
	position: absolute;
	transform-origin: 0% 100%;
	bottom: 0;
	right: 0;
	left: 0;
	width: auto;
	text-align: left;
	padding-left: 25%;
    text-wrap: wrap;
}

.content {
  width: 65vw;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}
.sec-head {
	margin: 0;
	padding: 10px;
	color: #000;
	font-size: 48px;
}
.sec-body {
	padding: 10px;
	font-size: 36px;
}
.sec-body p {
	color: #000;
	line-height: 1.4;
}
.sec-body p + p {
	margin-top: 10px;
}

.ArcWheelNav {
  height: 100vh;
  display: flex;
  align-items: center; /* Center vertically */
}

.center-page {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  height: 100vh; /* Full viewport height */
}

.centered-content {
  text-align: center;
}

.centered-content h1 {
  font-size: 3rem; /* Adjust font size as needed */
}