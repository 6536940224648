@import url("https://fonts.googleapis.com/css2?family=Playfair+Display+SC:ital,wght@0,100;0,200;0,400;0,700;0,900;1,100;1,200;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,400;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --sakurafresh-cream: #f5efe6;

  --sakurafresh-red-100: #fffafd;
  --sakurafresh-red-200: #f1bec4;
  --sakurafresh-red-800: #382525;

  --sakurafresh-green-100: #f5f5e6;
  --sakurafresh-green-800: #35361f;

  --sakurafresh-gray-50: #ffffff;
  --sakurafresh-grey-800: #707070;

  --sakurafresh-gold-100: #f8f3e9;
  --sakurafresh-gold-600: #c3924b;

  --sakurafresh-brown-500: #421c07;

  --sakurafresh-font-size-900: 86px;
  --sakurafresh-leading-900: 64px;
  --sakurafresh-font-size-800: 50px;
  --sakurafresh-leading-800: 45px;
  --sakurafresh-font-size-700: 40px;
  --sakurafresh-leading-700: 32px;
  --sakurafresh-font-size-600: 30px;
  --sakurafresh-leading-600: 26px;
  --sakurafresh-font-size-500: 24px;
  --sakurafresh-leading-500: 24px;
  --sakurafresh-font-size-400: 20px;
  --sakurafresh-leading-400: 20px;
  --sakurafresh-font-size-300: 16px;
  --sakurafresh-leading-300: 20px;
  --sakurafresh-font-size-200: 14px;
  --sakurafresh-leading-200: 18px;
  --sakurafresh-font-size-100: 12px;
  --sakurafresh-leading-100: 15px;
}

@media (-webkit-device-pixel-ratio: 1.5) {
  img {
    zoom: 80%;
  }
}

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
}

.hide-scroll::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

.responsive-text {
  font-size: 18px;
}

@media screen and (min-width: 1440px) and (max-width: 2560px) {
  .responsive-text {
    font-size: 20px;
  }
}

/* Track */
.hide-scroll::-webkit-scrollbar-track {
  background: #f1bec4;
}

/* Handle */
.hide-scroll::-webkit-scrollbar-thumb {
  transition: all 0.3s ease-in-out;
  background: #f1bec4;
  border-radius: 20px;
}

/* Handle on hover */
.hide-scroll::-webkit-scrollbar-thumb:hover {
  background: #f1bec4;
}

.glass-morphism {
  background: rgba(249, 249, 249, 0.74);
  backdrop-filter: blur(6.9px);
  -webkit-backdrop-filter: blur(6.9px);
}

/* Customize scrollbar */
/* WebKit */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #f1bec4; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Hover color of the scrollbar thumb */
}

.glow-on-hover {
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #fffafd,
    #f1bec4,
    #382525,
    #ffffff,
    #f8f3e9,
    #c3924b,
    #fffafd
  );
  /* background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000); */
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #c3924b;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}